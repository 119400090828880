"use strict";

import m from 'mithril';
import L from 'leaflet';
import { basemapLayer } from 'esri-leaflet';

/* jshint ignore:start */
const platform_settings = JSON.parse(
  document.getElementById("platform-settings")?.textContent ?? "{}"
);
/* jshint ignore:end */
/* global platform_settings */

const ProjectMapInner = {
  view: () => m('.project__map-inner'),
  oncreate: (vnode) => {
    let map_options = {
      scrollWheelZoom: false,
      zoomControl: false,
      dragging: false,
      doubleClickZoom: false,
      boxZoom: false,
      keyboard: false,
      touchZoom: false,
    }

    const map = L.map(vnode.dom, map_options);
    const layer = L.geoJSON(vnode.attrs.boundary);
    layer.addTo(map);

    map.fitBounds(layer.getBounds());
    if(!platform_settings.demo_mode) {
      basemapLayer('Imagery', {
        maxNativeZoom: 17,
        maxZoom: 21,
        zIndex: -1000,
      }).addTo(map);
    }
  }
}

const ProjectMapEmpty = {
  view: () => m('.project__map-empty', m('i.project__map-icon.fa.fa-map-marker-alt')),
}

export const ProjectMap = function(initialVnode) {
  let project_pk;
  let boundary;

  const getBoundary = (project_pk) => {
    m.request({
      method: 'GET',
      url: `/api/project/${project_pk}/boundary/`,
    })
    .then((data) => {
      boundary = data.boundary;
    });
  };

  const setProjectPk = (project_pk) => {
    project_pk = project_pk;
    getBoundary(project_pk);
  };

  return {
    oninit: (vnode) => {
      setProjectPk(vnode.attrs.project_pk);
    },
    view: () => m('.project__map', boundary ? m(ProjectMapInner, {boundary: boundary}) : m(ProjectMapEmpty)),
  }
};
